.modal {
  position: relative;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: block;

  .modal-mask {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    animation-duration: 280ms;
    position: fixed;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-root {
    background-color: #ffffff;
    border-radius: 10px;
    width: calc(100% - 24px);
    max-width: 450px;
    min-height: 200px;
    position: fixed;
    z-index: 8;
    left: 50%;
    top: 5vh;
    transform: translateX(-50%);
  }

  .modal-title {
    position: relative;
    padding: 12px;
    border-bottom: 1px solid #c4c4c4;

    h4 {
      font-size: 18px;
      color: rgb(0, 0, 0);
      font-weight: bold;
      margin-bottom: 0;
    }

    .btn-close {
      box-shadow: none;
      border: none;
      outline: 0;
      background: transparent;
      position: absolute;
      right: 12px;
      top: 10px;

      svg {
        font-size: 24px;
      }
    }
  }

  .chat-list {
    max-height: calc(100vh - 450px);
    overflow: hidden;
    overflow-y: auto;
  }

  .modal-content {
    padding: 12px 12px 24px;
  }

  .form-control {
    width: 100%;
    height: 38px;
    border: 1px solid #cacaca;
    padding: 4px 11px;
    font-size: 16px;
    border-radius: 8px;
  }

  .modal-footer {
    padding: 12px;

    .btn-group {
      display: flex;
      gap: 10px;
    }

    .btn-cancel {
      border: none;
      box-shadow: none;
      outline: 0;
      background: #ffffff;
      border: 1px solid rgb(153, 153, 153);
      border-radius: 22px;
      font-size: 14px;
      color: rgb(19, 19, 19);
      text-align: center;
      padding: 11px;
      margin-left: 11px;
      padding: 5px 15px;

      &:hover,
      &:focus {
        background: #e7f6ff;
      }
    }

    .btn-confirm {
      border: none;
      box-shadow: none;
      outline: 0;
      background: #ffffff;
      color: #0074e0;
      border: 1px solid rgb(153, 153, 153);
      border-radius: 22px;
      font-size: 14px;
      text-align: center;
      flex: 1 1 0%;
      padding: 11px;
      margin-left: 11px;
      height: 38px;
    }

    .btn-contact-cskh {
      border: none;
      box-shadow: none;
      outline: 0;
      background-image: linear-gradient(
        to right,
        rgb(254, 136, 70),
        rgb(249, 55, 130)
      );
      border-radius: 22px;
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-align: center;
      padding: 5px 15px;
      margin-right: 11px;
      height: 32px;
      width: 100%;

      &:hover,
      &:focus {
        background-image: linear-gradient(
          to right,
          rgb(226, 110, 42),
          rgb(202, 7, 82)
        );
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    .modal-root {
      top: 0;
      width: 100%;
      border-radius: 0;
      height: 100vh;
    }

    .chat-list {
      max-height: calc(100vh - 350px);
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.modal-image {
  .modal-root {
    max-width: 65%;
    overflow: hidden;
    overflow-y: auto;

    img {
      width: 100%;
      max-height: calc(70vh);
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal.modal-image .modal-root {
    height: auto;
    max-width: 90%;
    top: 10vh;
  }
}

.badge {
  background-color: #e00000;
  color: #ffffff;
  padding: 4px 11px;
  font-size: 10px;
  margin-left: 5px;
}
