:root {
  --vz-body-color: #141414;
  --theme-color: #0062d3;
  --main-color: #0cc77f;
  --body-color: #f7f6fb;
  --content-color: #f0f2f1;
  --text-color: #333333;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after,
:before {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  margin: 0;
  line-height: inherit;
  background-color: var(--body-color);
}

#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  opacity: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}
