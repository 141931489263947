.cskh-admin {
  .border-light {
    border-color: var(--vz-light) !important;
  }

  .chat-leftsidebar {
    padding: 8px 0;
    height: calc(100vh - 64px);
    position: relative;
    background-color: #ffffff;

    p {
      margin-bottom: 0;
    }
  }

  .dropdown {
    position: absolute;
    right: 10px;
    top: 10px;

    .dropdown-menu {
      background: #ffffff;
      min-width: 150px;
      display: none;

      &.show {
        display: block;
      }

      .dropdown-item {
        padding: 4px 11px;
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }

  .d-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .d-lg-flex {
    display: flex;
  }

  .p3 {
    padding: 1rem !important;
  }

  @media (min-width: 992px) {
    .p-lg-4 {
      padding: 1.5rem !important;
    }
  }

  .flex-grow-1 {
    display: flex;
  }

  .btn-toolbar .input-group,
  .col-auto {
    width: auto;
  }

  .row {
    display: flex;
    gap: 5px;

    .col-auto {
      flex: 0 0 auto;
    }

    .col {
      flex: 1 1 auto;
    }
  }

  textarea.form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    min-height: calc(1.5em + 1rem + 2px);
    background-color: #f8f8f8;
    color: #686868;
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 15px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .align-items-center {
    align-items: center !important;
  }

  .gap-1 {
    gap: 0.25rem;
  }

  .w-100 {
    width: 100%;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  @media (min-width: 992px) {
    .chat-leftsidebar {
      min-width: 300px;
      max-width: 300px;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .nav-pills > li > a,
  .nav-tabs > li > a {
    color: var(--vz-gray-700);
    font-weight: 500;
  }

  .avatar-xxs {
    height: 1.5rem;
    width: 1.5rem;

    img {
      &::before {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .avatar-xs {
    height: 2rem;
    width: 2rem;

    img {
      &::before {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .avatar-s {
    height: 2.5rem;
    width: 2.5rem;

    img {
      &::before {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  .avatar {
    position: relative;

    img {
      width: 100%;
      height: auto;
      border: none;
      background: transparent;

      &::before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        background-image: url("../img/user_default.jpg");
        background-size: contain;
      }
    }
  }
  .badge {
    font-size: 11px;
    padding: 2px 6px;
    border-radius: 10px;
  }

  .badge-soft-dark {
    color: #ffffff;
    background-color: rgba(221, 3, 3, 0.719);
  }

  .chat-user-img {
    position: relative;

    .user-status {
      width: 10px;
      height: 10px;
      background-color: #adb5bd;
      border-radius: 50%;
      border: 2px solid var(--vz-card-bg-custom);
      position: absolute;
      right: 0;
      left: auto;
      bottom: 0;
    }

    &.online {
      .user-status {
        background-color: #67b173;
      }
    }

    &.away {
      .user-status {
        background-color: #ffc84b;
      }
    }
  }

  .nav-tabs-custom {
    border-bottom: 1px solid var(--vz-gray-300);

    .nav-item {
      position: relative;

      .nav-link {
        border: none;
        font-weight: 500;

        &::after {
          content: "";
          background: #3d78e3;
          height: 1px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          -webkit-transition: all 250ms ease 0s;
          transition: all 250ms ease 0s;
          -webkit-transform: scale(0);
        }

        &.active {
          color: #3d78e3;

          &::after {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }

    &.nav-success {
      .nav-item {
        .nav-link {
          &.active {
            color: #67b173;
            background-color: var(--vz-card-bg-custom);

            &::after {
              background-color: #67b173;
            }
          }
        }
      }
    }
  }

  .chat-list {
    margin: 10px 0;

    li {
      list-style: none;

      &.active,
      &:hover {
        .item {
          background-color: rgba(var(--vz-success-rgb), 0.15);
          color: var(--vz-success);

          .badge {
            background-color: rgba(var(--vz-success-rgb), 0.15) !important;
            color: #67b173 !important;
          }
        }
      }

      .item {
        display: block;
        padding: 7px 24px;
        color: var(--vz-body-color);
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-weight: 500;
        font-size: 0.875rem;
        cursor: pointer;

        p {
          margin: 0;
        }
      }
    }
  }

  .user-chat-topbar {
    border-bottom: 1px solid transparent;
    background-color: #ffffff;

    .username {
      cursor: pointer;
    }
  }

  .user-chat {
    background: url(../img/chat-bg-pattern.png);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    position: relative;
    background-color: #f3f3f9;

    .chat-content {
      position: relative;
    }
  }

  .chat-conversation {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: calc(100vh - 225px);
  }

  @media (min-width: 992px) {
    .chat-conversation {
      height: calc(100vh - 185px);
    }

    .user-chat {
      &.user-chat-show {
        .chat-content {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }
      }
    }
  }

  .simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  .simplebar-height-auto-observer-wrapper {
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    -webkit-box-flex: inherit;
    -ms-flex-positive: inherit;
    flex-grow: inherit;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }

  .simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
  }

  .simplebar-offset {
    direction: inherit !important;
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0 !important;
    bottom: 0;
    right: 0 !important;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }

  .simplebar-content-wrapper {
    direction: inherit;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    padding: 0 !important;
  }

  .chat-conversation .simplebar-content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    overflow: hidden scroll;
  }

  .chat-conversation .simplebar-content-wrapper .simplebar-content {
    margin-top: auto;
    padding: 24px;
  }

  .chat-conversation .chat-conversation-list {
    padding: 0;
    margin-bottom: 0;
  }

  .chat-conversation .chat-conversation-list > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .chat-conversation .conversation-list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    max-width: 80%;
  }

  .chat-conversation .chat-avatar {
    margin: 0 16px 0 0;
    cursor: pointer;
  }

  .chat-conversation .chat-avatar img {
    width: 28px;
    height: 28px;
    border-radius: 50%;

    &::before {
      width: 28px;
      height: 28px;
    }
  }

  .chat-conversation .conversation-list .ctext-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 4px;
  }

  .chat-conversation .conversation-list .ctext-wrap-content {
    padding: 6px 14px;
    background-color: #ffffff;
    position: relative;
    border-radius: 8px 8px 16px 16px;
    -webkit-box-shadow: 0 1px 5px rgba(30, 32, 37, 0.12);
    box-shadow: 0 1px 5px rgba(30, 32, 37, 0.12);
    z-index: 9;
    min-height: 40px;
    min-width: 180px;
  }

  .chat-conversation .conversation-list .ctext-content {
    word-wrap: break-word;
    word-break: break-word;
    line-height: 20px;
    margin: 0;
  }

  .chat-conversation .conversation-list .ctext-wrap:hover {
    .message-box-drop {
      visibility: visible;
    }
  }

  .chat-conversation .chat-list .message-box-drop {
    visibility: visible;
    .dropdown {
      position: absolute;
      right: initial;
      top: 0;
      left: -50px;
    }
  }

  .chat-conversation .conversation-list .dropdown .dropdown-toggle {
    font-size: 18px;
    padding: 4px;
    color: #878a99;
  }

  .chat-conversation .conversation-list .conversation-name {
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
  }

  .chat-conversation .chat-list.left .check-message-icon {
    display: none;
  }

  .chat-conversation .right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    background-color: rgba(103, 177, 115, 0.15);
    text-align: right;
    -webkit-box-shadow: none;
    box-shadow: none;

    p {
      line-height: 20px;
      margin: 0;
    }
  }

  .chat-conversation .right .conversation-list .conversation-name {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .edit-chat {
    font-size: 10px;
    color: #525252;
    margin-left: 15px;
    font-style: italic;
  }

  // chat input
  .chat-input-section {
    border-top: 1px solid transparent;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    width: 100%;

    .chat-input-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      width: 100%;
      align-items: center;
      padding: 10px 15px;
      box-sizing: border-box;
      gap: 10px;

      .col {
        flex: 1 0;
      }

      .btn {
        background: transparent;

        &.btn-success {
          background-color: #67b173;
          color: #ffffff;
        }
      }
    }
  }

  textarea.form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    min-height: calc(1.5em + 1rem + 2px);
    background-color: #f8f8f8;
    color: #686868;
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 15px;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .chat-input-section {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 99;
      width: 100%;
      height: 75px;
    }

    .chat-conversation {
      height: calc(100vh - 140px);
    }
  }

  .chat-input-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .chat-input-links .links-list-item > .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    width: 37.5px;
    height: 37.5px;
  }

  .chat-input-links .links-list-item > .btn.btn-link {
    color: #878a99;
  }

  .chat-input-section .chat-input-feedback {
    display: none;
    position: absolute;
    top: -20px;
    left: 4px;
    font-size: 12px;
    color: #f17171;
  }

  .search-box-group {
    display: flex;
    gap: 2px;
    padding: 6px;

    input {
      padding: 4px 11px;

      &:hover,
      &:active,
      &:focus {
        outline: 0;
        border: none;
        box-shadow: none;
      }
    }

    .search-box {
      flex: 1 1 auto;
    }

    .btn-close-search {
      width: 45px;
      font-weight: 900;
      padding: 5px 6px;
      cursor: pointer;
      &:hover {
        background-color: #c4d4e9;
      }
    }
  }

  .search-box {
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 15px;
    overflow: hidden;
    height: fit-content;
    min-height: 35px;
  }

  .search-box .form-control {
    padding: 4px 10px;
    padding-left: 40px;
    min-height: 30px;
    border: none;
  }

  .search-box .search-icon {
    font-size: 14px;
    position: absolute;
    left: 13px;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #878a99;
  }

  .auth-form-user {
    width: 100%;

    ul {
      padding: 0;

      li {
        list-style: none;
      }
    }

    input {
      width: 100%;
      border-radius: 0.8rem;
      border: 0.0625rem solid #d6d5d6;
      padding: 0 11px;
      height: 40px;

      &:focus {
        box-shadow: none;
        outline: 0;
        border-color: #213e75;
      }
    }

    .form_T {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .marginBottom {
      margin-bottom: 15px;
    }

    .btn-submit {
      background: #67b173;
      color: #ffffff;
    }

    .error {
      input {
        border-color: #e61b00;
        color: #e61b00;

        &::placeholder {
          color: #c76e62;
        }
      }
    }

    .error_t {
      color: #e61b00;
      margin-top: 4px;
      display: block;
    }
  }

  .red {
    color: #e61b00;
  }

  .signin-other-title {
    margin-top: 12px;

    a {
      color: #2265df;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .left-menu {
    width: 64px;
  }

  .theme-desktop {
    display: flex;

    .left-menu {
      background-color: #0091ff;
      height: 100vh;
      position: relative;

      .header-avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        img {
          width: 48px;
          height: 48px;
          object-position: center;
          object-fit: cover;
          position: relative;

          &::before {
            width: 48px;
            height: 48px;
          }
        }
      }

      .remix-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .remix-menu-footer {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .btn-menu {
        width: 100%;
        background: transparent;
        border: none;
        box-shadow: none;
        color: #ffffff;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &.active,
        &:hover,
        &:focus {
          background: #0065b3;
        }

        svg {
          font-size: 26px;
        }
      }

      .btn-logout {
        background-color: #0065b3;

        &:hover {
          background-color: #004174;
        }
      }
    }

    .main-content {
      width: calc(100% - 64px);
    }
  }

  .footer-mobile {
    position: fixed;
    z-index: 999;
    background: #ffffff;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(184, 184, 184, 0.53);
    -moz-box-shadow: 0px -1px 10px 0px rgba(184, 184, 184, 0.53);
    box-shadow: 0px -1px 10px 0px rgba(184, 184, 184, 0.53);

    .footer_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      flex: 1 1 auto;
      color: #8b8b8b;

      &.active {
        color: #0070e0;
      }

      h5 {
        font-size: 13px;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  .chat-notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: auto;
      max-width: 160px;
    }

    p {
      font-size: 20px;
      font-weight: 900;
      color: #61a6eb;
      font-family: monospace, Arial, Helvetica, sans-serif;
    }
  }

  .chat-buddle {
    position: fixed;
    z-index: 999;
    left: 300px;
    bottom: 64px;
    width: 50px;
    height: 50px;
    background-color: #0087ee;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 10px 0px rgb(184, 184, 184);
    -moz-box-shadow: 0px 1px 10px 0px rgb(184, 184, 184);
    box-shadow: 0px 1px 10px 0px rgb(184, 184, 184);

    &:hover {
      background-color: #1981d1;
      -webkit-box-shadow: 0px 1px 10px 0px rgb(139, 139, 139);
      -moz-box-shadow: 0px 1px 10px 0px rgb(139, 139, 139);
      box-shadow: 0px 1px 10px 0px rgb(139, 139, 139);
    }

    svg {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    .chat-buddle {
      left: initial;
      right: 20px;
      bottom: 80px;
    }
  }

  .btn-back {
    background: transparent;
    width: 35px;
    height: auto;
    padding: 0;
    color: #6b6b6b;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: #004174;
      outline: 0;
      box-shadow: none;
      border: 0;
    }

    svg {
      font-size: 22px;
    }
  }

  .list-select-user {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 10px;

    .item {
      padding: 2px 10px;
      background-color: #e6e6e6;
      font-size: 12px;
      border-radius: 2px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .btn-destroy {
        width: 20px;
        height: 16px;
        display: inline-block;
        text-align: center;
        font-size: 14px;
      }

      &:hover {
        background-color: #c5f0fd;
      }
    }
  }

  .user-chat-content {
    .username {
      color: #b32400;
      margin-bottom: 0;
      font-weight: 700;
    }

    img {
      max-width: 180px;
    }
  }

  .dropdown-menu {
    position: absolute;
    z-index: 999;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 41px);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(199, 199, 199, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(199, 199, 199, 1);
    box-shadow: 0px 1px 5px 0px rgba(199, 199, 199, 1);

    .dropdown-item {
      cursor: pointer;
    }
  }

  .btn-icon {
    width: 39px;
    height: 39px;

    svg {
      font-size: 18px;
    }

    &.show {
      border: none;
      box-shadow: none;
      outline: 0;
      background-color: rgba(91, 113, 185, 0.1);
      color: #5b71b9;
    }
  }

  .text-right {
    text-align: right !important;
  }

  .text-left {
    text-align: left !important;
  }

  .mt-12 {
    margin-top: 12px !important;
  }

  // upload file

  .upload-container {
    position: relative;

    input[type="file"] {
      display: none;
    }

    .btn {
      padding: 0;
      margin: 0;
      color: #8b8b8b;

      svg {
        font-size: 30px;
      }

      &:hover,
      &:focus {
        color: #0065b3;
      }
    }
  }

  .btn-cskh {
    background-color: #0070e0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-left: 5px;
    height: 30px;
    padding: 4px 11px;
    border: none;
    box-shadow: none;
    outline: 0;
    border-radius: 6px;
    line-height: 30px;

    &:hover,
    &:focus {
      background-color: #005db9;
      color: #ffffff;
    }
  }

  .btn-sm {
    font-size: 12px;
    min-width: 25px;
  }

  .text-help {
    color: #666666;
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow {
    display: none;
  }
}
