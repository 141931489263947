.sbui-sidepanel-overlay--enter {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.sbui-sidepanel-overlay--enterFrom {
  opacity: 0;
}
.sbui-sidepanel-overlay--enterTo {
  opacity: 1;
}
.sbui-sidepanel-overlay--leave {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.sbui-sidepanel-overlay--leaveFrom {
  opacity: 1;
}
.sbui-sidepanel-overlay--leaveTo {
  opacity: 0;
}
.sbui-sidepanel--enter {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (min-width: 1024px) {
  .sbui-sidepanel--enter {
    transition-duration: 0.3s;
  }
}
.sbui-sidepanel--enterFrom {
  --tw-translate-x: 100%;
}
.sbui-sidepanel--enterFrom--left {
  --tw-translate-x: -100%;
}
.sbui-sidepanel--enterTo {
  --tw-translate-x: 0px;
}
.sbui-sidepanel--leave {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (min-width: 1024px) {
  .sbui-sidepanel--leave {
    transition-duration: 0.3s;
  }
}
.sbui-sidepanel--leaveFrom {
  --tw-translate-x: 0px;
}
.sbui-sidepanel--leaveTo {
  --tw-translate-x: 100%;
}
.sbui-sidepanel--leaveTo--left {
  --tw-translate-x: -100%;
}
.sbui-sidepanel-overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.sbui-sidepanel-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(15, 15, 15, var(--tw-bg-opacity));
  opacity: 0.75;
}
.sbui-sidepanel--left {
  left: 0;
  padding-right: 2.5rem;
}
.sbui-sidepanel--right {
  right: 0;
  padding-left: 2.5rem;
}
.sbui-sidepanel--medium {
  height: 100%;
  width: 100vw;
  // max-width: 500px;
}
.sbui-sidepanel--wide {
  height: 100%;
  width: 100vw;
  max-width: 42rem;
}
.sbui-sidepanel-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100vh;
  max-width: 100%;
  z-index: 999;
  background-color: #fff;
  .sbui-sidepanel-header {
    > div {
      align-items: center;
      justify-content: space-between;
    }
  }
}
.sbui-sidepanel {
  display: flex;
  height: 100%;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .sbui-sidepanel {
  --tw-bg-opacity: 1;
  background-color: var(--bg-color);
}
.sbui-sidepanel {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.sbui-sidepanel-header > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.sbui-sidepanel-header {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .sbui-sidepanel-header {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}
.sbui-sidepanel-header {
  padding: 1.5rem 1rem;
}
@media (min-width: 640px) {
  .sbui-sidepanel-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.sbui-sidepanel-close-container {
  display: flex;
  height: 1.75rem;
  align-items: center;
}
.dark .sbui-sidepanel-close-container button,
.sbui-sidepanel-close-container button {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}
.dark .dark .sbui-sidepanel-close-container button,
.dark .sbui-sidepanel-close-container button,
.dark .sbui-sidepanel-close-container button:hover,
.sbui-sidepanel-close-container button:hover {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}
.dark .dark .sbui-sidepanel-close-container button:hover,
.dark .sbui-sidepanel-close-container button:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}
.dark .sbui-sidepanel-close-container button:hover,
.sbui-sidepanel-close-container button:hover {
  background-color: transparent;
}
.sbui-sidepanel-content {
  position: relative;
  flex: 1 1 0%;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 640px) {
  .sbui-sidepanel-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.sbui-sidepanel-footer-container {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .sbui-sidepanel-footer-container {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}
.sbui-sidepanel-footer-container {
  padding: 1rem;
}
.sbui-sidepanel-footer {
  display: flex;
  flex-shrink: 0;
}
.sbui-sidepanel__trigger {
  border-style: none;
  background-color: transparent;
  padding: 0;
}
.sbui-sidepanel__trigger:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.sbui-input-icon-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}
.dark .sbui-input-icon-container {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.sbui-checkbox[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}
.sbui-checkbox[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
}
.dark .sbui-checkbox[type="checkbox"]:checked,
.sbui-checkbox[type="checkbox"]:checked {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 207, 142, var(--tw-bg-opacity));
}
.sbui-checkbox[type="checkbox"]:checked {
  border-color: transparent;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.sbui-checkbox {
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border: 1px solid rgba(187, 187, 187, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(62, 207, 142, var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.sbui-checkbox:hover {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
}
.sbui-checkbox:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 231, 199, var(--tw-ring-opacity));
}
.dark .sbui-checkbox {
  --tw-border-opacity: 1;
  border-color: rgba(102, 102, 102, var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .sbui-checkbox:hover {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
}
.sbui-checkbox {
  margin-top: 2px;
}
.sbui-checkbox-container {
  display: flex;
  cursor: pointer;
}
.sbui-checkbox__label-container {
  line-height: 1;
}
.sbui-checkbox__label-container__label {
  margin-top: 0;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
}
.dark .sbui-checkbox__label-container__label {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.sbui-checkbox__label-container__label__span {
  font-weight: 500;
}
.sbui-checkbox__label-container__label__span .sbui-checkbox__label-text-after,
.sbui-checkbox__label-container__label__span .sbui-checkbox__label-text-before {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}
.dark
  .sbui-checkbox__label-container__label__span
  .sbui-checkbox__label-text-after,
.dark
  .sbui-checkbox__label-container__label__span
  .sbui-checkbox__label-text-before {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}
.sbui-checkbox__label-container__label__p {
  margin-top: 0;
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}
.dark .sbui-checkbox__label-container__label__p {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}
.sbui-checkbox-container--tiny .sbui-checkbox__label-container__label,
.sbui-checkbox-container--tiny .sbui-checkbox__label-container__label__p,
.sbui-checkbox-container--tiny .sbui-checkbox__label-container__label__span {
  font-size: 0.75rem;
  line-height: 1rem;
}
.sbui-checkbox-container--medium .sbui-checkbox__label-container__label,
.sbui-checkbox-container--medium .sbui-checkbox__label-container__label__p,
.sbui-checkbox-container--medium .sbui-checkbox__label-container__label__span,
.sbui-checkbox-container--small .sbui-checkbox__label-container__label,
.sbui-checkbox-container--small .sbui-checkbox__label-container__label__p,
.sbui-checkbox-container--small .sbui-checkbox__label-container__label__span {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.sbui-checkbox-container--large .sbui-checkbox__label-container__label,
.sbui-checkbox-container--large .sbui-checkbox__label-container__label__p,
.sbui-checkbox-container--large .sbui-checkbox__label-container__label__span,
.sbui-checkbox-container--xlarge .sbui-checkbox__label-container__label,
.sbui-checkbox-container--xlarge .sbui-checkbox__label-container__label__p,
.sbui-checkbox-container--xlarge .sbui-checkbox__label-container__label__span {
  font-size: 1rem;
  line-height: 1.5rem;
}
.sbui-checkbox-container--tiny .sbui-checkbox {
  margin-top: 0.25rem;
  margin-right: 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
}
.sbui-checkbox-container--small .sbui-checkbox {
  margin-top: 0.125rem;
  margin-right: 0.875rem;
  height: 0.875rem;
  width: 0.875rem;
}
.sbui-checkbox-container--medium .sbui-checkbox {
  margin-top: 0.125rem;
  margin-right: 0.875rem;
  height: 1rem;
  width: 1rem;
}
.sbui-checkbox-container--large .sbui-checkbox,
.sbui-checkbox-container--xlarge .sbui-checkbox {
  margin-top: 0.125rem;
  margin-right: 1rem;
  height: 1.25rem;
  width: 1.25rem;
}

a.sbui-typography-link {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.dark a.sbui-typography-link,
a.sbui-typography-link {
  color: rgb(62, 207, 142);
}
